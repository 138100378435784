<template>
    <div>
        <div class="join_now">
            <div class="container">
                <p>Our Mission</p>
                <h1>Bring hope to patients through AI</h1>
                <!-- <button>Join Now</button> -->
            </div>
        </div>
        <footer class="footer">
            <div class="container">
                <div class="foot_wrap">
                    <div class="left_foot">
                        <h5>HopeAI, Inc.</h5>
                        <p>103 Carnegie Center Dr, Princeton, NJ 08540</p>
                    </div>
                    <div class="left_foot content_email">
                        <h5>Contact</h5>
                        <a href="mailto:will@hopeai.co" title="will@hopeai.co">info@hopeai.co</a>

                    </div>
                    <div class="right_foot">
                        <h5>Copyright</h5>
                        <p>©2025. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<style lang="scss">
.join_now {
    background: url(/img/Mask.png) #6939A3 no-repeat center center;
    background-size: cover;
    padding: 80px 0 134px 0;

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #E919D4;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-bottom: 12px;
    }

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 52px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.3px;
        /* text-transform: capitalize; */
        color: #FFFFFF;
        flex: none;
        order: 1;
        flex-grow: 0;
        max-width: 400px;
        margin: 0 auto;
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25);
    }

    button {
        background: #FFFFFF;
        box-shadow: 0px 40px 70px rgba(65, 24, 162, 0.25);
        border-radius: 8px;
        border: 0;
        width: 251.3px;
        height: 68.05px;
        display: block;
        margin: 64px auto 0 auto;
        font-weight: 600;
        font-size: 20px;
        line-height: 34px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #6939A3;
    }

}

.footer {
    background: #0D121F;
    color: #fff;
    padding: 20px 0;

    .container {
        display: flex;
        justify-content: space-between;

        .foot_wrap {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .left_foot {
                h5 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                a {
                    font-size: 14px;
                    color: #fff;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .right_foot {
                h5 {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                a {
                    color: #999;
                    text-decoration: none;
                    font-size: 16px;
                    font-family:
                        -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
                        Helvetica Neue, Helvetica, Arial, sans-serif;
                    line-height: 22px;
                    display: inline-block;
                }

                p {
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .footer {
        padding: 14px 0 120px 0;

        .foot_wrap {
            flex-wrap: wrap;
            height: auto;
            margin-top: 20px;
        }

        .left_foot {
            margin-bottom: 30px;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .join_now h1 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .join_now {
        background-position: -70px 45px;
    }
}
</style>