
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as companyKmOl8cC36qMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/company.vue?macro=true";
import { default as deckT6HR61Lh6aMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/deck.vue?macro=true";
import { default as indexJxpYUai3MLMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/index.vue?macro=true";
import { default as productODn0XM6f7bMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/product.vue?macro=true";
import { default as PUREEvidenceg2U8keslqCMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/PUREEvidence.vue?macro=true";
import { default as research0ZZyxKSmCcMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/research.vue?macro=true";
import { default as synthIPDnhUsBaCsGXMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/synthIPD.vue?macro=true";
export default [
  {
    name: "company",
    path: "/company",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/company.vue")
  },
  {
    name: "deck",
    path: "/deck",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/deck.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/index.vue")
  },
  {
    name: "product",
    path: "/product",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/product.vue")
  },
  {
    name: "PUREEvidence",
    path: "/PUREEvidence",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/PUREEvidence.vue")
  },
  {
    name: "research",
    path: "/research",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/research.vue")
  },
  {
    name: "synthIPD",
    path: "/synthIPD",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/synthIPD.vue")
  }
]